import { lazy, Suspense } from "react";
import { Navigate, RouteObject } from "react-router-dom";
import LoaderSpinner from "../shared/components/Loader/Loader";
import ProtectedRoute from "../shared/components/RouteProtection";
import { USER_ROLES } from "../shared/enums/sharedEnums";
import { sharedRoutes } from "../shared/routes/routes";
import { profferRoutes } from "./routes/routes";

// Lazy loading components
const Dashboard = lazy(() => import("./pages/Dashboard"));
const Home = lazy(() => import("./pages/Home"));
const CenterProfile = lazy(() => import("./pages/Profiles/CenterProfile"));
const CenterProfileView = lazy(() => import("./pages/Profiles/CenterProfileView"));
const ParentProfileHistory = lazy(() => import("./pages/Profiles/ParentProfileHistory"));
const AddNewVacancy = lazy(() => import("./pages/addVacancy/AddNewVacancy"));
const BiddingRequestScreen = lazy(() => import("./pages/biddingRequest/BiddingRequestScreen"));
const CenterHomeScreen = lazy(() => import("./pages/centerHome/CenterHomeScreen"));
const Notification = lazy(() => import("./pages/notification/Notification"));
const ParentHomeScreen = lazy(() => import("./pages/parentHome/ParentHomeScreen"));
const Profferlist = lazy(() => import("./pages/prOfferResult/Profferlist"));
const VacanciesHistoryScreen = lazy(() => import("./pages/vacanciesHistory/VacanciesHistoryScreen"));
const VacancyViewScreen = lazy(() => import("./pages/vacancyView/VacancyViewScreen"));
const VacanciesDashboard = lazy(() => import("./pages/parentDashboard/VacanciesDashboard"));

const ProfferModuleRoutes: RouteObject[] = [
  {
    path: profferRoutes.home,
    element: (
      <Suspense fallback={<LoaderSpinner />}>
        <Home />
      </Suspense>
    ),
  },
  {
    path: profferRoutes.center_home,
    element: (
      <Suspense fallback={<LoaderSpinner />}>
        <CenterHomeScreen />
      </Suspense>
    ),
  },
  {
    path: profferRoutes.parent_home,
    element: (
      <Suspense fallback={<LoaderSpinner />}>
        <ParentHomeScreen />
      </Suspense>
    ),
  },
  {
    path: profferRoutes.proffer_list,
    element: (
      <Suspense fallback={<LoaderSpinner />}>
        <Profferlist />
      </Suspense>
    ),
  },
  {
    path: profferRoutes.center_profile_view,
    element: (
      <Suspense fallback={<LoaderSpinner />}>
        <CenterProfileView />
      </Suspense>
    ),
  },
  {
    path: "/",
    element: <ProtectedRoute role={USER_ROLES.CENTER} />,
    children: [
      {
        path: profferRoutes.dashboard,
        element: (
          <Suspense fallback={<LoaderSpinner />}>
            <Dashboard />
          </Suspense>
        ),
      },
      {
        path: profferRoutes.add_vacancy,
        element: (
          <Suspense fallback={<LoaderSpinner />}>
            <AddNewVacancy />
          </Suspense>
        ),
      },
      {
        path: profferRoutes.bidding_request,
        element: (
          <Suspense fallback={<LoaderSpinner />}>
            <BiddingRequestScreen />
          </Suspense>
        ),
      },
      {
        path: profferRoutes.vacancies_history,
        element: (
          <Suspense fallback={<LoaderSpinner />}>
            <VacanciesHistoryScreen />
          </Suspense>
        ),
      },
      {
        path: profferRoutes.view_vacancy,
        element: (
          <Suspense fallback={<LoaderSpinner />}>
            <VacancyViewScreen />
          </Suspense>
        ),
      },
      // {
      //   path: sharedRoutes.center_profile,
      //   element: (
      //     <Suspense fallback={<LoaderSpinner />}>
      //       <CenterProfile />
      //     </Suspense>
      //   ),
      // },
      {
        path: profferRoutes.notifications,
        element: (
          <Suspense fallback={<LoaderSpinner />}>
            <Notification />
          </Suspense>
        ),
      },
      {
        path: "*",
        element: (
          <Suspense fallback={<LoaderSpinner />}>
            <Navigate to={profferRoutes.dashboard} />
          </Suspense>
        ),
      },
    ],
  },
  {
    path: "/",
    element: <ProtectedRoute role={USER_ROLES.PARENT} />,
    children: [
      {
        path: profferRoutes.proffer_list,
        element: (
          <Suspense fallback={<LoaderSpinner />}>
            <Profferlist />
          </Suspense>
        ),
      },
      // {
      //   path: profferRoutes.parent_profile,
      //   element: (
      //     <Suspense fallback={<LoaderSpinner />}>
      //       <ParentProfileHistory />
      //     </Suspense>
      //   ),
      // },
      {
        path: profferRoutes.parent_dashboard_vacancies,
        element: (
          <Suspense fallback={<LoaderSpinner />}>
            <VacanciesDashboard />
          </Suspense>
        ),
      },
      {
        path: profferRoutes.parent_dashboard_history,
        element: (
          <Suspense fallback={<LoaderSpinner />}>
            <VacanciesHistoryScreen />
          </Suspense>
        ),
      },
      {
        path: "*",
        element: (
          <Suspense fallback={<LoaderSpinner />}>
            <Navigate to={profferRoutes.proffer_list} />
          </Suspense>
        ),
      },
    ],
  },
  { path: "*", element: <Navigate to={profferRoutes.home} /> },
];

export default ProfferModuleRoutes;
