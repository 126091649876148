import { useEffect, useState } from "react";
import { Navigate, useLocation, useRoutes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { setAuthUser } from "./features/Auth Slice/authSlice";
import "./global.css";
import AdventureModuleRoutes from "./modules/adventures_module/adventureRoutes";
import CCFModuleRoutes from "./modules/ccfWebsite_module/ccfRoutes";
import { ccfRoutes } from "./modules/ccfWebsite_module/routes/routes";
import ForayModuleRoutes from "./modules/foray_module/forayRoutes";
import MedEvalModuleRoutes from "./modules/medEvaluation_module/medEvalRoutes";
import CenterBooking from "./modules/medEvaluation_module/pages/medEvaluationCenterBooking/CenterBooking";
import MedEvaluationDate from "./modules/medEvaluation_module/pages/medEvaluationDate/MedEvaluationDate";
import { medEvalRoutes } from "./modules/medEvaluation_module/routes/routes";
import DashboardPaymentMethod from "./modules/proffer_module/pages/parentDashboard/DashboardPaymentMethod";
import ProfferModuleRoutes from "./modules/proffer_module/profferRoutes";
import LoaderSpinner from "./modules/shared/components/Loader/Loader";
import { STORAGE_KEYS } from "./modules/shared/enums/sharedEnums";
import LoaderPage from "./modules/shared/pages/LoaderPage";
import AboutUs from "./modules/shared/pages/aboutUs/AboutUs";
import BlogDetails from "./modules/shared/pages/blogsScreen/BlogDetails";
import BlogsScreen from "./modules/shared/pages/blogsScreen/BlogsScreen";
import ComingSoon from "./modules/shared/pages/comingSoon/ComingSoon";
import ContactUs from "./modules/shared/pages/contactUs/ContactUs";
import PrivacyStatement from "./modules/shared/pages/terms&Condition/PrivacyStatement";
import TermsConditions from "./modules/shared/pages/terms&Condition/Terms&Conditions";
import { sharedRoutes } from "./modules/shared/routes/routes";
import spinnerSvc from "./modules/shared/utils/spinner-service";
import { store } from "./store/store";
import SharedModuleRoutes from "./modules/shared/sharedRoutes";
import { documentTitle } from "./modules/shared/utils/documentTitle";
import { QueryClientProvider } from "@tanstack/react-query";
import { queryClient } from "./config/useQuery.config";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
// const LoaderPage = lazy(() => import("./modules/shared/pages/LoaderPage"));

function App() {
  const [loading, setLoading] = useState(null);
  const pathName = useLocation().pathname;
  // const pathName = localStorage.getItem(STORAGE_KEYS.PATH_NAME);
  // const checkForTokens = async () => {
  //   const res = await checkForTokensExpiry(redirectURI as string);
  //   if (res?.accToken) {
  //     return;
  //   } else if (res?.rfToken) {
  //     const res = await refreshToken();
  //     if (!res) {
  //       signOut();
  //       navigate(profferRoutes.home);
  //       return;
  //     }
  //     return;
  //   } else {
  // signOut();
  //     navigate(profferRoutes.home);
  //   }
  //   // console.log("token check: ", checkForTokensExpiry());
  // };
  // useEffect(() => {
  // if (!pathName) checkForTokens();
  // }, []);
  useEffect(() => {
    const subscription = spinnerSvc.requestInProgress.subscribe((data: any) => {
      setLoading(data);
    });
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  const userDetails = JSON.parse(
    localStorage.getItem(STORAGE_KEYS?.USER) as string
  );
  useEffect(() => {
    // if (!userDetails?.id || userDetails?.role !== "admin") return;
    store.dispatch(setAuthUser(userDetails));
  }, [userDetails]);

  useEffect(() => {
    documentTitle(pathName);
  }, [pathName]);

  const routing = useRoutes([
    { path: sharedRoutes.auth_verify, element: <LoaderPage /> },
    { path: sharedRoutes.about_us, element: <AboutUs /> },
    { path: sharedRoutes.contact_us, element: <ContactUs /> },
    { path: sharedRoutes.terms_and_conditions, element: <TermsConditions /> },
    { path: sharedRoutes.privacy_policy, element: <PrivacyStatement /> },
    { path: sharedRoutes.blogs_screen, element: <BlogsScreen /> },
    { path: sharedRoutes.blogs_details, element: <BlogDetails /> },
    
    { path: sharedRoutes.coming_soon, element: <ComingSoon /> },
    { path: "/", element: <Navigate to={ccfRoutes.home} /> },

    ...SharedModuleRoutes,
    ...CCFModuleRoutes,
    ...AdventureModuleRoutes,
    ...MedEvalModuleRoutes,
    ...ProfferModuleRoutes,
    ...ForayModuleRoutes,
  ]);

  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} /> {/* DevTools added here */}
      {loading && <LoaderSpinner />}
      <ToastContainer
        position="top-right"
        autoClose={4000}
        // hideProgressBar={false}
        // newestOnTop={false}
        // closeOnClick
        // rtl={false}
        // pauseOnFocusLoss
        // draggable
        // pauseOnHover
        theme="colored"
        // transition= Bounce
      />
      {routing}
    </QueryClientProvider>
  );
}

export default App;
