import React from "react";
import DashboardWrapper from "../../../shared/components/layout/DashboardWrapper";
import { SlLockOpen } from "react-icons/sl";
import { Link } from "react-router-dom";
import { forayRoutes } from "../../routes/routes";

const ForayScreen = () => {
  return (
    <>
      <DashboardWrapper>
        <div className="pt-5 flex items-center justify-center">
          <div className="max-w-[872px]">
            <h2 className="text-2xl text-secondary font-semibold mb-6">
              Foray Features
            </h2>
            <p className="text-lg text-secondaryVariant mb-6 lg:mb-16">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Et
              adipisci officia, inventore hic sunt similique impedit unde aut
              maiores sapiente dolorem nobis consequatur ad fuga veniam quisquam
              in ipsa cum!
            </p>
            <div className="grid grid-cols-4 gap-2.5">
              <div className="rounded-xl p-6 flex items-center gap-6 bg-white flex-col hover:bg-secondaryNeutral">
                <i className="text-white bg-secondaryVariant2 rounded-lg p-2">
                  <SlLockOpen className="w-11 h-11" />
                </i>
                <span className="text-lg text-secondaryVariant font-medium text-truncate line-clamp-1 text-center">
                  Dashboard
                </span>
              </div>
              <Link
                to={forayRoutes.center_view}
                className="rounded-xl p-6 flex items-center gap-6 bg-white flex-col hover:bg-secondaryNeutral"
              >
                <i className="text-white bg-secondaryVariant2 rounded-lg p-2">
                  <SlLockOpen className="w-11 h-11" />
                </i>
                <span className="text-lg text-secondaryVariant font-medium text-truncate line-clamp-1 text-center">
                  Center Setup
                </span>
              </Link>
              <Link
                to={forayRoutes.enrollment_tracking}
                className="rounded-xl p-6 flex items-center gap-6 bg-white flex-col hover:bg-secondaryNeutral"
              >
                <i className="text-white bg-secondaryVariant2 rounded-lg p-2">
                  <SlLockOpen className="w-11 h-11" />
                </i>
                <span className="text-lg text-secondaryVariant font-medium text-truncate line-clamp-1 text-center">
                  Enrollment Tracking
                </span>
              </Link>
              <div className="rounded-xl p-6 flex items-center gap-6 bg-white flex-col hover:bg-secondaryNeutral">
                <i className="text-white bg-secondaryVariant2 rounded-lg p-2">
                  <SlLockOpen className="w-11 h-11" />
                </i>
                <span className="text-lg text-secondaryVariant font-medium text-truncate line-clamp-1 text-center">
                  Task Generation
                </span>
              </div>
              <div className="rounded-xl p-6 flex items-center gap-6 bg-white flex-col hover:bg-secondaryNeutral">
                <i className="text-white bg-secondaryVariant2 rounded-lg p-2">
                  <SlLockOpen className="w-11 h-11" />
                </i>
                <span className="text-lg text-secondaryVariant font-medium text-truncate line-clamp-1 text-center">
                  Checklists
                </span>
              </div>
              <Link
                to={forayRoutes.hr_dashboard}
                className="rounded-xl p-6 flex items-center gap-6 bg-white flex-col hover:bg-secondaryNeutral"
              >
                <i className="text-white bg-secondaryVariant2 rounded-lg p-2">
                  <SlLockOpen className="w-11 h-11" />
                </i>
                <span className="text-lg text-secondaryVariant font-medium text-truncate line-clamp-1 text-center">
                  Human Resource Managements
                </span>
              </Link>
              <div className="rounded-xl p-6 flex items-center gap-6 bg-white flex-col hover:bg-secondaryNeutral">
                <i className="text-white bg-secondaryVariant2 rounded-lg p-2">
                  <SlLockOpen className="w-11 h-11" />
                </i>
                <span className="text-lg text-secondaryVariant font-medium text-truncate line-clamp-1 text-center">
                  QR-Code
                </span>
              </div>
              <div className="rounded-xl p-6 flex items-center gap-6 bg-white flex-col hover:bg-secondaryNeutral">
                <i className="text-white bg-secondaryVariant2 rounded-lg p-2">
                  <SlLockOpen className="w-11 h-11" />
                </i>
                <span className="text-lg text-secondaryVariant font-medium text-truncate line-clamp-1 text-center">
                  Child Reports
                </span>
              </div>
              <div className="rounded-xl p-6 flex items-center gap-6 bg-white flex-col hover:bg-secondaryNeutral">
                <i className="text-white bg-secondaryVariant2 rounded-lg p-2">
                  <SlLockOpen className="w-11 h-11" />
                </i>
                <span className="text-lg text-secondaryVariant font-medium text-truncate line-clamp-1 text-center">
                  Quality Improvement
                </span>
              </div>
              <div className="rounded-xl p-6 flex items-center gap-6 bg-white flex-col hover:bg-secondaryNeutral">
                <i className="text-white bg-secondaryVariant2 rounded-lg p-2">
                  <SlLockOpen className="w-11 h-11" />
                </i>
                <span className="text-lg text-secondaryVariant font-medium text-truncate line-clamp-1 text-center">
                  LMS
                </span>
              </div>
              <div className="rounded-xl p-6 flex items-center gap-6 bg-white flex-col hover:bg-secondaryNeutral">
                <i className="text-white bg-secondaryVariant2 rounded-lg p-2">
                  <SlLockOpen className="w-11 h-11" />
                </i>
                <span className="text-lg text-secondaryVariant font-medium text-truncate line-clamp-1 text-center">
                  Financial Tracking
                </span>
              </div>
            </div>
          </div>
        </div>
      </DashboardWrapper>
    </>
  );
};

export default ForayScreen;
